import React from "react";
import { Tabs, Tab } from "react-bootstrap";
//import Tabs from 'react-bootstrap/Tabs';
//import Tab from 'react-bootstrap/Tab';
import propTypes from "prop-types";
import SessionTab from "../Components/Tabs/SessionTab";
import ChannelsTab from "../Components/Tabs/ChannelsTab";
import Viewer from "../Containers/Viewer";
import StoredValuestab from "../Components/Tabs/StoredValuesTab";
import SpreadChart from "../Components/Charts/SpreadChart";
import ThroughputChart from "../Components/Charts/ThroughputChart";
import SizeChart from "../Components/Charts/SizeChart";
import VisibilityChart from "../Components/Charts/VisibilityChart";
import DownloadTab from "./DownloadTab";
import "./InformationTabs.css";
import Highcharts from "highcharts";
import { withHighcharts } from "react-jsx-highcharts";

/* 

  This container is responsible for rendering Tabs and graph components.
  The Tabs and charts are all components. E.g. they just render and dont fetch any data.

*/
class InformationTabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      index: "",
      key: 1
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    //this.updateScroll();

    if (this.props !== prevProps) {
      this.setState({
        key: 1
      });
    }
  }


  componentWillUnmount (){
    ;
  }

  componentDidMount(){
   ;

  }


  handleSelect(key) {
    this.setState({ key });
    if (this.props.scrollToBottom)
      this.props.scrollToBottom();
  }

  exportSizeDistributionTocsv = () => {
    let { data } = this.props;



    let fishWeight = "";
    if (data.Fishweight !== undefined) {
      let header = "Fish weight (g):\r\n";
      fishWeight = header + data.Fishweight.join("\r\n") + "\r\n\r\n";
    }
    let csvContent =
      "data:text/csv;charset=utf-8," + 
      "Channels:\r\n" + data.Ori.ChannelName.join("\r\n") + "\r\n\r\n" +
      "Weight range (g): (divide by 10 to get group size)\r\n" + data.Distribution.DistributionChartUnit.join("\r\n") + "\r\n\r\n" +
      "Distribution:\r\n" + data.Distribution.DistributionChart.join("\r\n") + "\r\n\r\n" + 
      fishWeight;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", data.Program.Tank + " - SizeDistributionChart.csv");
    document.body.appendChild(link);
    link.click();
  }





  exportFormattedThroughputTocsv = () => {   //One record pr/minute, all channels joined
    let { data } = this.props;

    let throughputCharts = "Time, Fish per minute\r\n";



    if (data.newThroughputChart !== undefined) {
      let header = "Unix time stamp in ms,Fish per minute\r\n";
    
      let tc = data.newThroughputChart;
     let len = tc.length; //Give number of channels

      let sumArr = [];
      let timeArr = [];
      sumArr.push(["", 0])

      let lenTime = tc[0].length;
      let cntMin = 0;
      let cntOneMin = 0;
      for (let i = 0; i<lenTime; i++){
        

        for (let j = 0; j<len; j++)
          sumArr[cntMin][1] += tc[j][i][1]; 



        cntOneMin++;
        if (cntOneMin == 6){
          sumArr[cntMin][1] /= 6;
          sumArr[cntMin][0] = new Date(tc[0][i][0]).toISOString();
          let tt = sumArr[cntMin][0].lastIndexOf (":");
          sumArr[cntMin][0] = sumArr[cntMin][0].substring (0,tt);
          sumArr[cntMin][0] = sumArr[cntMin][0].replace ("T"," ");
          throughputCharts += sumArr[cntMin][0]+ ", "+Math.round( sumArr[cntMin][1]).toString()+"\r\n"
          if (i < lenTime-1){
            sumArr.push(["", 0]);
            cntMin++;
          }
          cntOneMin = 0;

        }

  //     throughputCharts = header + data.newThroughputChart.map(e => e.join("\r\n")).join("\r\n\r\n" + header) + "\r\n\r\n";
    }


    return;
  }

 
    let throughputChartsMax = "";
    if (data.ThroughputChartMax !== undefined) {
      let header = "Unix time stamp in ms,Max fish per minute\r\n";
      throughputChartsMax = header + data.ThroughputChartMax.map(e => e.join("\r\n")).join("\r\n\r\n" + header) + "\r\n\r\n";
    }
    let csvContent =
      "data:text/csv;charset=utf-8," + 
      throughputCharts + 
      throughputChartsMax;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", data.Program.Tank + " - ThroughputChart.csv");
    document.body.appendChild(link);
    link.click();
  };


  exportThroughputTocsv = () => {
    let { data } = this.props;
    let throughputCharts = "";
    if (data.newThroughputChart !== undefined) {
      let header = "Unix time stamp in ms,Fish per minute\r\n";
      throughputCharts = header + data.newThroughputChart.map(e => e.join("\r\n")).join("\r\n\r\n" + header) + "\r\n\r\n";
    }
    let throughputChartsMax = "";
    if (data.ThroughputChartMax !== undefined) {
      let header = "Unix time stamp in ms,Max fish per minute\r\n";
      throughputChartsMax = header + data.ThroughputChartMax.map(e => e.join("\r\n")).join("\r\n\r\n" + header) + "\r\n\r\n";
    }
    let csvContent =
      "data:text/csv;charset=utf-8," + 
      throughputCharts + 
      throughputChartsMax;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", data.Program.Tank + " - ThroughputChart.csv");
    document.body.appendChild(link);
    link.click();
  };

  render() {
    let {
      data,
      pdfFileURL,
      mseS3Path,
      mseFileURL,
      blcFileURL,
      pdfFilePath,
      blcFilePath,
      cntFilePath,
      Index
    } = this.props;
    let { key, index } = this.state;
    let style = { paddingBottom: "20" };
    let TabIds;
    if (Index) {
      TabIds = `counter-info${Index}`; // This is for wellboat data.
    } else {
      TabIds = `counter-info`; // 'normal' counters
    }
    // Some older versions of the counters do not have a 'propper' visibility curve so
    // we create it from other sources
    // if(data.visibilityChartBegin === undefined){
    //   visibilityChartBefore =  data.visibilityStart;
    // }else{
    //   visibilityChartBefore =  data.visibilityChartBegin;
    // }





   
    return (
      <Tabs
        activeKey={key}
        onSelect={this.handleSelect}
        id={TabIds}
        className="information-tabs"
      >
        <Tab style={style} eventKey={1} title="Session Details">
          <SessionTab
            totalCount={data.Program.Total || {}}
            operator={data.Program.operator}
            tank={data.Program.Tank}
            from={data.Program.from}
            to={data.Program.to}
            start={data.Program.BeginTime}
            end={data.Program.EndTime}
            duration={data.Program.Duration}
            overload={data.Program.Overload}
            macAddress={data.Program.PC_MacAdress}
            dropxBox={data.Program.Dropbox_Computer_Number}
            serialNumber={data.Program.Camera_serial}
            cameraMacAddress={data.Program.Camera_MacAddress}
            scanRate={data.Settings.ScanRate}
            lightStrength={data.Settings.Amplification}
            magicNumber={data.Program.MagicNumber}
            softwareVersion={data.Settings.Version}
          />
          <hr />
          <DownloadTab
            index={index}
            pdfFileURL={pdfFileURL}
            mseFileURL={mseFileURL}
            blcFileURL={blcFileURL}
            mseS3Path={mseS3Path}
            pdfFilePath={pdfFilePath}
            blcFilePath={blcFilePath}
            cntFilePath={cntFilePath}
          />
        </Tab>
        <Tab style={style} eventKey={3} title="Channels">
          <ChannelsTab
            channelName={data.Ori.ChannelName}
            channelCounts={data.Ori.ChannelCounts}
            estimatedSize={data.Program.EstimatedSize}
            sizeGroupValues={data.Ori.SizeGroups}
          />
        </Tab>
        <Tab
          style={style}
          eventKey={4}
          title="Spread"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <SpreadChart
            key={4}
            title={data.Program.Tank}
            dataPoints={data.levelChart}
            plotLines={data.Ori.Multie}
            reggressionLines={data.EndPointRegression}
            channelName={data.Ori.ChannelName}
          />
        </Tab>
        <Tab
          style={style}
          eventKey={5}
          title="Throughput"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <ThroughputChart
            title={data.Program.Tank}
            dataPoints={data.newThroughputChart}
            dataPointsMax={data.ThroughputChartMax}
            channelNames={data.Ori.ChannelName}
            zoom={"x"}
            exportToCSV={this.exportThroughputTocsv}
          />
        </Tab>
        <Tab
          style={style}
          eventKey={6}
          title="Size Distribution"
          mountOnEnter={true}
        >
          <SizeChart
            distributionChart={data.Distribution.DistributionChart}
            xAxisUnit={data.Distribution.DistributionChartUnit}
            ChannelName={data.Ori.ChannelName}
            exportToCSV={this.exportSizeDistributionTocsv}
          />
        </Tab>
        <Tab style={style} eventKey={7} title="Visibility" mountOnEnter={true}>
          <VisibilityChart
            //dataPointsAfter={data.visibilityChart}
            //dataPointsBefore = {visibilityChartBefore}
            dataPointsAfter={data.VisibilityChart}
            dataThreshold={data.ThresholdChart}
            plotLines={data.Ori.Multie}
            dataPointsBefore={data.VisibilityChart_Begin}
          />
        </Tab>
        <Tab style={style} eventKey={8} title="Stored Values">
          {data.storedValues ? (
            <StoredValuestab
              channelNames={data.Ori.ChannelName}
              storedValues={data.storedValues}
              //storedValues={data.storedValues}
            />
          ) : (
            <h4>No stored Values</h4>
          )}
        </Tab>
        <Tab style={style} eventKey={9} title="Count viewer" mountOnEnter={true} unmountOnExit={true} >
  
            <Viewer
              Path={blcFilePath}
              ScanRate = {data.Settings.ScanRate}
              NrChannels = {data.Ori.ChannelName.length}
              Ori = {data.Ori}
              title={data.Program.Tank}
              dataPoints={data.newThroughputChart}
              dataPointsMax={data.ThroughputChartMax}
              channelNames={data.Ori.ChannelName}
              zoom={"x"}
              exportToCSV={this.exportThroughputTocsv}
              userData = {this.props.userData}


              //storedValues={data.storedValues}
            />





        </Tab>


      </Tabs>
    );
  }
}

export default withHighcharts(InformationTabs, Highcharts);

propTypes.CountTable = {
  CountRows: propTypes.list,
  isLoading: propTypes.boolen,
  getMSEFile: propTypes.function,
  getMultipleMSEFile: propTypes.function,
  setMSEFileURL: propTypes.function,
  setPDFFileURL: propTypes.function,
  setBLCFileURL: propTypes.function
};
